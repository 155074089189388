import { memo } from "react"
import { handleGtag } from "./gtag"
import "../styles/main.scss"

const SectionHelp = memo(() => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h4 style={{ fontSize: "16px", fontWeight: "600", marginBottom: "22px", lineHeight: "28px", color: "#fafafa" }}>Help</h4>
      <p style={{ lineHeight: "38px", cursor: "pointer", color: "#fafafa" }} onClick={e => { handleGtag(e, "footer terms"); window.open(`${window.location.origin}/terms.html`, "_blank"); window.scrollTo({ top: 0, behavior: "smooth" }) }}>
        Terms of Use
      </p>
      <p style={{ lineHeight: "38px", cursor: "pointer", color: "#fafafa" }} onClick={e => { handleGtag(e, "footer privacy policy"); window.open(`${window.location.origin}/privacy-policy.html`, "_blank"); window.scrollTo({ top: 0, behavior: "smooth" }) }}>
        Privacy Policy
      </p>
      <a href="https://versi0n.io/contact-us" target="_blank" rel="noreferrer" style={{ lineHeight: "38px", cursor: "pointer", color: "#fafafa" }} onClick={e => handleGtag(e, "footer contact us")}>
        Contact Us
      </a>
    </div>
  )
})
const SectionCompany = memo(() => {
  return (
    <div>
      <h4 style={{ fontSize: "16px", fontWeight: "600", marginBottom: "22px", lineHeight: "28px", color: "#fafafa" }}>Company</h4>
      <p style={{ lineHeight: "38px", cursor: "pointer", color: "#fafafa" }} onClick={e => { handleGtag(e, "footer platform"); window.open(`https://versi0n.io/`, "_blank") }}>
        Platform
      </p>
      <p style={{ lineHeight: "38px", cursor: "pointer", color: "#fafafa" }} onClick={e => { handleGtag(e, "footer founder"); window.open(`https://kuriko.vercel.app/`, "_blank") }}>
        Founder
      </p>
    </div>
  )
})


export const Footer = memo(() => {
  return (
    <footer className="has-background-dark" style={{ minWidth: "100%", paddingTop: "20px", marginTop: "10px" }}>
      <div className="container" style={{ display: "flex", justifyContent: "space-between", padding: "40px 0 90px 0" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "19px" }}>
          <h4 style={{ fontSize: "16px", fontWeight: "600", marginBottom: "22px", lineHeight: "28px", color: "#fafafa" }} itemProp="name">
            Version IO Sdn Bhd
          </h4>
          <p style={{ color: "#fafafa" }}>Learn. Communicate. Convert with AI.</p>
        </div>
        <div style={{ display: "flex", gap: "77px" }}>
          <SectionHelp />
          <SectionCompany />
        </div >
      </div >

      <hr style={{ minWidth: "100%", margin: "0 0 5px 0", border: "1px solid #fafafa", opacity: ".4", backgroundColor: "#fafafa" }} />

      <div className="container" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 0 50px 0" }}>
        <a href="/" onClick={e => handleGtag(e, "footer")} style={{ color: "#fafafa" }}>
          &copy; 2024-{new Date().getFullYear()} <span className="general-link"> Version IO Sdn Bhd.</span> All Rights Reserved
        </a>
      </div>
    </footer >
  )
})


export const MobileFooter = memo(() => {
  return (
    <footer className="has-background-dark" style={{ width: "100%", paddingTop: "20px", marginTop: "10px" }}>
      <div className="container" style={{ display: "flex", justifyContent: "space-between", padding: "40px 20px 90px 20px", width: "300px" }}>
        <SectionHelp />
        <SectionCompany />
      </div>

      <hr style={{ minWidth: "100%", margin: "0 0 5px", border: "1px solid #fafafa", opacity: ".4", backgroundColor: "#fafafa" }} />

      <div className="container" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0 50px 0" }}>
        <a href="/" onClick={e => handleGtag(e, "footer")} style={{ color: "#fafafa" }}>
          &copy; 2024-{new Date().getFullYear()} <span className="general-link" itemProp="name"> Version IO Sdn Bhd.</span>
          <br />
          All Rights Reserved
        </a>
      </div>
    </footer>
  )
})