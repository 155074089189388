import { useState } from "react"
import { handleGtag } from "./gtag"
import { FaLinkedin, FaGithub } from "react-icons/fa"
import "../styles/main.scss"


export const Header = () => {
  return (
    <nav className="navbar container" style={{ display: "flex", padding: "0 20px" }}>
      <div className="navbar-start" style={{ display: "flex" }}>
        <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleGtag(e, "header")}>
          <img
            src="https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1728219760/pj_m_home/j80bjzkwuvtethz1dybf.png"
            alt="Home" height="40" width="100%" style={{ minHeight: "40px" }}
          />
        </a>

        <a className="navbar-item" aria-label="navbar item" href="/#how-it-works" onClick={e => handleGtag(e, "header")}>
          How It Works
        </a>

        <div className="navbar-item has-dropdown is-hoverable">
          <a className="navbar-link" href="/#use-cases" onClick={e => handleGtag(e, "header")}>
            Use Cases
          </a>

          <div className="navbar-dropdown">
            <a className="navbar-item" aria-label="navbar item" href="/#use-cases" onClick={e => handleGtag(e, "header")}>
              Upsell, Cross-sell
            </a>
            <a className="navbar-item" aria-label="navbar item" href="/#use-cases" onClick={e => handleGtag(e, "header")}>
              Lead Generation
            </a>
            <a className="navbar-item" aria-label="navbar item" href="/#use-cases" onClick={e => handleGtag(e, "header")}>
              Churn Prevention
            </a>
          </div>
        </div>

        <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="https://versi0n.io" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
          Playground
        </a>
      </div>

      <div className="navbar-end" style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
        <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://github.com/versionHQ/multi-agent-system" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
          <FaGithub style={{ height: "100px" }} />
        </a>
        <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://www.linkedin.com/in/k-i-i/" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
          <FaLinkedin style={{ height: "100px" }} />
        </a>
        <a className="button is-dark" aria-label="navbar item" style={{ display: "flex", alignItems: "center", height: "80%", color: "#fafafa", marginLeft: "10px" }} href="/#custom-demo" onClick={e => handleGtag(e, "header")}>
          Schedule Demo
        </a>
      </div>
    </nav>
  )
}



export const MobileHeader = () => {
  const [isOpenBurger, setIsOpenBurger] = useState(false)

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <div className="navbar-start" style={{ display: "flex" }}>
          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="/">
            <img
              src="https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1728219760/pj_m_home/j80bjzkwuvtethz1dybf.png"
              alt="Home" height="40" width="100%" style={{ minHeight: "40px" }}
            />
          </a>
        </div>

        <div className={isOpenBurger ? "navbar-burger is-active" : "navbar-burger"} onClick={() => setIsOpenBurger(!isOpenBurger)} style={{ color: "#191b1b" }}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      {isOpenBurger &&
        <div className="navbar-dropdown" id="burger-menu">
          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="/#how-it-works" onClick={e => handleGtag(e, "header")}>
            How It Works
          </a>
          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", flexDirection: "column" }} href="/#use-cases" onClick={e => handleGtag(e, "header")}>
            Use Cases
            <ul style={{ marginLeft: "30px" }}>
              <li>Upsell, Cross-sell</li>
              <li>Churn Prevention</li>
              <li>Lead Generation</li>
            </ul>
          </a>

          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="https://versi0n.io" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
            Playground
          </a>
          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="/#custom-demo" onClick={e => handleGtag(e, "header")}>
            Schedule Demo
          </a>
          <a className="navbar-item" aria-label="navbar item" href="#founder" onClick={e => handleGtag(e, "header")}>
            About Us
          </a>
          <hr />
          <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://github.com/versionHQ/multi-agent-system" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
            Contribute <FaGithub style={{ height: "20px" }} />
          </a>
        </div>
      }
    </nav>
  )
}