import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Home, MobileHome } from "./components/Home"


export const App = ({ tabletMode }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={tabletMode ? <MobileHome /> : <Home />} />
      </Routes>
      <SpeedInsights />
    </BrowserRouter >
  )
}
